<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('user.wizard.fourth_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <card footer-classes="text-right" class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('user.wizard.personal_data') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.firstname')"
                                       :value="user | optional('firstname')"/>
            <list-group-item-component :label="$t('fields.lastname')"
                                       :value="user | optional('lastname')"/>
            <list-group-item-component :label="$t('fields.username')"
                                       :value="user | optional('username')"/>
            <list-group-item-component :label="$t('fields.operator_number')"
                                       :value="user | optional('operator_number')"/>
            <list-group-item-component :label="$t('fields.active')"
                                       :value="user.active ? $t('common.yes') : $t('common.no')"/>
          </ul>
        </card>
      </div>
      <div class="col-12 col-md-4">
        <card footer-classes="text-right" class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('user.wizard.contact_data') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('page.phones')" :value="user.phones.length"/>
            <list-group-item-component :label="$t('page.emails')" :value="user.emails.length"/>
            <list-group-item-component :label="$t('page.addresses')" :value="user.addresses.length"/>
          </ul>
        </card>
        <card footer-classes="text-right" class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('common.roles') }}</h4>
          </div>
          <badge v-for="(role, index) in user.roles"
                 v-bind:key="index"
                 :type="role.name | roleColor"
                 class="text-uppercase small mr-2">
            {{ $t('auth.roles.' + role.name) }}
          </badge>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import User from "@/models/user";
import Badge from "@/components/Badge";

export default {
  name: "NewUserWizardFourthStep",
  components: {Badge, ListGroupItemComponent},
  props: {
    user: {
      type: Object,
      default: _.cloneDeep(User)
    }
  },
  computed: {
    roleNames() {
      return this.$_.map(this.user.roles, role => {
        return this.$t('auth.roles.' + role.name);
      });
    }
  }
}
</script>

<style scoped>

</style>
