<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col text-center">
        <p>{{ $t('user.wizard.second_step_title') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <octo-entity-addresses ref="octoEntityAddresses"/>
      </div>
    </div>
  </div>
</template>

<script>
  import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";

  export default {
    name: "NewUserWizardSecondStep",
    components: {OctoEntityAddresses},
    methods: {
      validate() {
        return this.$refs.octoEntityAddresses.getEntityAddresses();
      }
    }
  }
</script>

<style scoped>

</style>
