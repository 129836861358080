<template>
  <card body-classes="standard-card-body" class="mr-2 shadow">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('common.roles') }}</h4>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <li v-for="(role, index) in userRolesChunks[0]" v-bind:key="index"
              class="standard-li">
            <div class="standard-label">
              {{ $t('auth.roles.' + role.name) }}
            </div>
            <el-switch v-model="role.checked"/>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <li v-for="(role, index) in userRolesChunks[1]" v-bind:key="index"
              class="standard-li">
            <div class="standard-label">
              {{ $t('auth.roles.' + role.name) }}
            </div>
            <el-switch v-model="role.checked"/>
          </li>
        </ul>
      </div>
    </div>
  </card>
</template>

<script>
import {mapGetters} from "vuex";
import {Switch} from "element-ui";


export default {
  name: "UserRoles",
  components: {
    [Switch.name]: Switch,
  },
  data() {
    return {
      userRoles: []
    }
  },
  props: {
    roles: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      allRoles: 'auth/roles',
    }),

    userRolesChunks() {
      return this.$_.chunk(this.userRoles, this.$_.ceil(this.userRoles.length / 2));
    }
  },
  mounted() {
    this.$_.each(this.$_.cloneDeep(this.allRoles), (role) => {
      role.checked = !!this.$_.find(this.roles, ['name', role.name]);
      this.userRoles.push(role)
    });
  },
  methods: {
    getUserRoles: function () {
      const roles = [];
      this.$_.each(this.userRoles, (role) => {
        if (role.checked) {
          roles.push(this.$_.find(this.allRoles, ['name', role.name]))
        }
      });

      return roles;
    }
  }
}
</script>

<style scoped>

</style>
