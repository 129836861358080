<template>
  <div class="row">
    <div class="col-md-12">
      <simple-wizard
        @tab-change="tabChange"
        @onCompleted="completeWizard"
        :finishButtonText="$t('common.finish')"
        :nextButtonText="$t('common.next')"
        :prevButtonText="$t('common.prev')"
      >
        <template slot="header">
          <h3 class="card-title">{{ $t('user.wizard.title') }}</h3>
          <h3 class="description">{{ $t('user.wizard.subtitle') }}</h3>
        </template>

        <wizard-tab :before-change="() => validateStep(1)">
          <template slot="label">
            <octo-icon icon="user"/>
            <p>{{ $t('user.wizard.personal_data') }}</p>
          </template>
          <new-user-wizard-first-step ref="wizardFirstStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(2)">
          <template slot="label">
            <octo-icon icon="list"/>
            <p>{{ $t('user.wizard.addresses') }}</p>
          </template>
          <new-user-wizard-second-step ref="wizardSecondStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(3)">
          <template slot="label">
            <octo-icon icon="contact"/>
            <p>{{ $t('user.wizard.contact_data') }}</p>
          </template>
          <new-user-wizard-third-step ref="wizardThirdStep"/>
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep(4)">
          <template slot="label">
            <octo-icon icon="checked"/>
            <p>{{ $t('user.wizard.report') }}</p>
          </template>
          <new-user-wizard-fourth-step :user="user" :key="reportKey"/>
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>

<script>
import SimpleWizard from "@/components/Wizard/Wizard";
import WizardTab from "@/components/Wizard/WizardTab";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import User from "@/models/user";
import NewUserWizardFirstStep from "@/pages/Users/user-wizard/NewUserWizardFirstStep";
import NewUserWizardSecondStep from "@/pages/Users/user-wizard/NewUserWizardSecondStep";
import NewUserWizardThirdStep from "@/pages/Users/user-wizard/NewUserWizardThirdStep";
import NewUserWizardFourthStep from "@/pages/Users/user-wizard/NewUserWizardFourthStep";
import {mapMutations} from "vuex";
import {MessageBox} from "element-ui";

export default {
  name: "WizardPage",
  components: {
    NewUserWizardFourthStep,
    NewUserWizardThirdStep,
    NewUserWizardSecondStep,
    NewUserWizardFirstStep,
    OctoIcon,
    WizardTab,
    SimpleWizard,
  },
  data() {
    return {
      user: this.$_.cloneDeep(User),
      reportKey: 0,
    }
  },
  methods: {
    ...mapMutations({
      updateUsers: 'common/updateUsers'
    }),

    async validateStep(step) {
      let check = false;
      switch (step) {
        case 1:
          const user = await this.$refs.wizardFirstStep.validate();
          if (user) {
            this.user = user;
            check = true;
          }
          break
        case 2:
          const addresses = this.$refs.wizardSecondStep.validate();
          if (addresses) {
            this.$set(this.user, 'addresses', addresses);
            check = true;
          }
          break;
        case 3:
          const data = this.$refs.wizardThirdStep.validate();
          if (data) {
            this.$set(this.user, 'emails', data.emails);
            this.$set(this.user, 'phones', data.phones);
            check = true;
          }
          break;
        case 4:
          check = true;
          break;
        default:
          check = false;
          break;
      }

      if (!check) {
        this.$notify({type: 'danger', message: this.$t('notifications.wizard_step_error')});
      }

      return check;
    },

    tabChange(oldTab, newTab) {
      if (newTab.tabId === '3') {
        this.reportKey++;
      }
    },

    completeWizard() {
      this.$api.post(endpoints.USER_STORE, this.user)
        .then((resp) => {
          this.updateUsers(resp?.data?.data);

          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('user.wizard.back_on_user_index_page'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          })
            .finally(() => {
              this.$router.push({name: 'users.index'})
            });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>
