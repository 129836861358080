<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('user.wizard.third_step_title') }}</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <octo-entity-emails ref="octoEntityEmails"/>
      </div>
      <div class="col-12 col-md-10">
        <octo-entity-phones ref="octoEntityPhones"/>
      </div>
    </div>
  </div>
</template>

<script>
  import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
  import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";

  export default {
    name: "NewUserWizardThirdStep",
    components: {OctoEntityPhones, OctoEntityEmails},
    methods: {
      validate() {
        return {
          emails: this.$refs.octoEntityEmails.getEntityEmails(),
          phones: this.$refs.octoEntityPhones.getEntityPhones()
        }
      }
    }
  }
</script>

<style scoped>

</style>
