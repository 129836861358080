<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-auto text-center">
        <p>{{ $t('user.wizard.first_step_title') }}</p>
        <p>{{ $t('user.wizard.first_step_subtitle') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <card footer-classes="text-right" class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('user.wizard.personal_data') }}</h4>
          </div>
          <user-form ref="userForm"/>
        </card>
      </div>
      <div class="col-12 col-md-4">
        <user-roles :roles="roles" ref="userRoles"/>
      </div>
    </div>
  </div>
</template>

<script>
  import UserForm from "../forms/UserForm";
  import UserRoles from "../components/UserRoles";

  export default {
    name: "NewUserWizardFirstStep",
    components: {UserRoles, UserForm},
    data() {
      return {
        roles: []
      }
    },
    methods: {
      validate() {
        return new Promise((resolve, reject) => {
          this.$refs.userForm.getUserData()
            .then((success) => {
              if (success) {
                success.roles = this.$refs.userRoles.getUserRoles();
                resolve(success);
              }
            })
            .catch((err) => {
              reject(err)
            });
        });
      }
    }
  }
</script>

<style scoped>

</style>
